<template>
  <div class="data-box">
    <div class="data-box-head">
      每次餐饮发放仅限一人一份
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in mealsKeyValueList" :key="index">
          <span class="span-key span-line">{{ item.key }}</span>
          <span class="span-value">{{ item.value ? item.value : "无" }}</span>
        </div>
      </div>
      <div class="body-div" v-show="isSubmit">
        <div class="body-div-title align-center">
          请确认以下发放信息准确
        </div>
        <div class="body-div-item">
          <div class="span-key span-line">用餐时间</div>
          <div class="span-value">{{$tool.dateTimeFormat(new Date())}}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key">领取餐食</div>
          <div class="span-input" @click="getMeals">
            <span>{{ sendInfo.mealsName ? sendInfo.mealsName : "请选择领取餐食" }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">验证码</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.code"
                placeholder="请输入领取人手机号码后4位"
                maxlength="4"
                type="digit"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">发放人</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePerson"
                placeholder="请输入发放人"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">联系电话</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePhone"
                placeholder="请输入联系电话"
                maxlength="11"
                type="digit"
            >
            </van-field>
          </div>
        </div>
      </div>
      <div class="body-success" v-show="!isSubmit">
        <div class="body-div-success">
          <van-image class="success-image" :src="require('../../assets/image/success.png')"/>
          <div class="success-title">已领取</div>
          <div class="success-time">{{ dateTime }}</div>
        </div>
      </div>
    </div>
    <div class="data-box-foot" v-show="isSubmit">
      <van-button round block type="info" @click="onSubmit">提交</van-button>
    </div>
    <van-action-sheet v-model="showMeals" :actions="mealsActions" :description="mealsActions.length > 0 ? '' : '暂无数据'" @select="selectMeals"></van-action-sheet>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  energyWashRoomFoodRegister,
  canteenFoodStatistics
} from "@/api/index";

export default {
  name: "parMeals_room_code",
  data() {
    return {
      mealsKeyValueList: [
        { key: "人员姓名", value: ""},
        { key: "用餐方式", value: ""},
        { key: "堂食餐厅", value: ""},
        { key: "餐厅地址", value: ""},
      ],
      showMeals: false,
      mealsActions: [],
      sendInfo: {
        actId: 0,
        activityId: 0,
        handlePerson	: "",
        handlePhone: "",
        hotelDiningId: null,
        roomEnergyFood: "",
        mealsName: "",
        num2: 1,
        type: 5,
        code: "",
        phone: ""
      },
      dateTime: "",
      isSubmit: true
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let query = that.$route.query;
      that.sendInfo.hotelDiningId = query.type;
      that.$tool.getActObjInfo(query.id, (data) => {
        that.sendInfo.actId = data.activityId;
        that.sendInfo.activityId = data.id;
        that.sendInfo.phone = data.chargePhone;
        that.mealsKeyValueList[0].value = data.chargeName;
        that.mealsKeyValueList[1].value = "堂食";
        that.mealsKeyValueList[2].value = query.room;
        that.mealsKeyValueList[3].value = query.address;
      });
      that.getMealsFood();
    },
    getMealsFood(){
      let that = this;
      let date = that.$tool.dateFormat(new Date());
      let params = {
        actId: that.$store.getters.getActivityId,
        startDate: date,
        endDate: date,
        relateType: that.sendInfo.hotelDiningId ,
        companyId: that.$store.getters.getCompanyId,
        pageNum: 1,
        pageSize: 9999,
        type: 3
      };
      canteenFoodStatistics(params).then(res => {
        that.$tool.resValidation(res,() => {
          that.mealsActions = res.data.records;
          if(that.mealsActions.length >= 1) {
            that.sendInfo.mealsName = that.mealsActions[0].str;
            that.sendInfo.roomEnergyFood = that.mealsActions[0].useObjType === 0 || that.mealsActions[0].useObjType === 1 ? that.mealsActions[0].id : that.mealsActions[0].useObjType;
          }
          that.mealsActions.forEach(item => {
            item.name = item.str;
          })
        })
      })
    },
    getMeals(){
      let that = this;
      that.showMeals = true;
    },
    selectMeals(item){
      let that = this;
      that.sendInfo.mealsName = item.name;
      if (item.useObjType == 0 || item.useObjType == 1) {
        that.sendInfo.roomEnergyFood = item.id
      }else {
        that.sendInfo.roomEnergyFood = item.useObjType
      }
      that.showMeals = false;
    },
    onSubmit(){
      let that = this;
      if(that.sendInfo.roomEnergyFood === ""){
        that.$toast('请选择领取餐食！');
        return;
      }
      if(that.sendInfo.code === ""){
        that.$toast('请输入手机后四位验证码！');
        return;
      }
      let str = that.sendInfo.phone.substring(that.sendInfo.phone.length-4);
      if(that.sendInfo.code != str){
        that.$toast('验证码有误！');
        return;
      }
      if(that.sendInfo.handlePerson === ""){
        that.$toast('请输入发放人姓名！');
        return;
      }
      if(that.sendInfo.handlePhone === ""){
        that.$toast('请输入发放人联系电话！');
        return;
      }
      energyWashRoomFoodRegister(that.sendInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.isSubmit = false;
          that.dateTime = that.$tool.dateTimeFormat(new Date());
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>